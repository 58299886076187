import React from "react";
import Link from "../Link";
import { useLocation } from "@reach/router";
import { LOCALES } from "../../config";

function renderChild(node: any) {
  const location = useLocation();
  const parts = location.pathname.split("/");
  const locale = parts.length > 1 && LOCALES.includes(parts[1]) ? parts[1] : "";
  let { marks, text, link, children } = node;
  children = children && children.length > 0 ? children.map(renderChild) : text;
  if (link && link.url) {
    children = (
      <a rel="noopener noreferrer" href={link.url}>
        {children}
      </a>
    );
  }

  if (link && link.reference && link.reference.__typename === "Page") {
    children = (
      <Link
        to={locale ? `/${locale}${link.reference.path}` : link.reference.path}
      >
        {children}
      </Link>
    );
  }

  if (marks.bold) {
    children = <strong>{children}</strong>;
  }

  if (marks.italic) {
    children = <em>{children}</em>;
  }

  if (marks.underlined) {
    children = <u>{children}</u>;
  }

  return children;
}

const Marks = ({ children }: any) => {
  return children
    ? children.map((node: any, index: number) => (
        <React.Fragment key={index}>{renderChild(node)}</React.Fragment>
      ))
    : null;
};

export default Marks;
