import React from "react";
import { useExternalLinks } from "../hooks/useExternalLinks";

import styled from "@emotion/styled";
import Footer from "./Footer";
import "./layout.css";
import Header from "./Header";
import { Cloud, CloudT } from "./Cloud";
import { TriangleTrees, TreeOne, TreeTwo, TreeThree } from "./Trees";

export interface Layout {
  content: {
    body: {
      children: [
        {
          __typename: any;
        }
      ];
    };
  };
  menus: any[];
  location: any;
  locale: string;
  children: any;
  translations: any;
}

const LayoutStyle = styled.div`
  font-family: "OpenSans", "Helvetica Neue", "Segoe UI", "Helvetica", "Arial",
    "sans-serif";
`;

const Content = styled.div`
  position: relative;
  min-height: 100vh;
  margin: -150px auto;
  max-width: 1150px;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px auto;
    padding: 0 20px;
  }
  li {
    max-width: 1100px;
    margin: auto;
    padding: 20px;
  }
  @media (max-width: 500px) {
    margin: -250px auto 0;
  }
`;

const LoginWrapper = styled.div`
  text-align: center;
  margin: 40px auto -100px;
  @media (min-width: 768px) {
    margin: 200px auto 0;
  }
`;

const LoginLink: any = styled.a`
  color: #abf422;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 1.2px;
  text-align: center;
  text-decoration: none;
  border-radius: 500px;
  background-color: #441152;
  border: solid 1px #ffc400;
  color: #ffc400;
  text-transform: uppercase;
  padding: 10px 24px;
  cursor: pointer;
`;

const Layout = ({
  location,
  locale,
  children,
  menus,
  pages,
  translations,
}: Layout) => {
  const footerMenu: any = menus.find(
    (menu: any) => menu.name === "Footer Menu"
  );
  const headerMenu: any = menus.find((menu: any) => menu.name === "Main Menu");

  const links = useExternalLinks();
  const affiliateLink = links.find((node: any) => node.title == "Affiliate");
  return (
    <LayoutStyle>
      <Header location={location} menu={headerMenu} locale={locale} />
      <Cloud />
      <CloudT />
      <TriangleTrees>
        <TreeOne />
        <TreeTwo />
        <TreeThree />
      </TriangleTrees>
      <Content>{children}</Content>
      <LoginWrapper>
        <LoginLink href={affiliateLink.url} rel={affiliateLink.rel}>
          {locale === "fi" ? "Pelaa" : "Start Playing"}
        </LoginLink>
      </LoginWrapper>
      <Footer footerMenu={footerMenu} location={location} pages={pages} />
    </LayoutStyle>
  );
};
export default Layout;
